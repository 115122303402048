import { useContentfulLiveUpdates } from "@contentful/live-preview/react"
import FlexibleData from "@lib/flexibleData"
import FilterGridTemplate from "@templates/filter-grid"
import { getBlogData } from "@lib/api"
import { fetchRecommended } from "@lib/fetchRecommended"
import { findNodeByType } from "@lib/utils"

export async function getStaticProps(context) {
  const preview = process.env.NODE_ENV === "development" || context.draftMode || false
  const previewEnv = context?.previewData?.environment || null
  const slug = "blog"
  const pageData = (await FlexibleData.fetch(slug, preview, previewEnv)) ?? []

  // Grab the title from the featuredContent to avoid duplication
  const featuredPageModules = pageData.modulesCollection?.items[0]?.modulesCollection?.items
  const contentBlock = featuredPageModules.find((module) => module?.__typename === "ContentBlock")
    ?.content?.json?.content
  const heading = findNodeByType(contentBlock, "heading-1")
  const title = findNodeByType(heading?.content, "hyperlink") || heading // Check for both unlinked and linked H1
  const finalHeader = title?.content[0]?.value

  const allBlogData = (await getBlogData(finalHeader, preview)) ?? []

  // Get a set of all unique authors/tags and spread into an array
  const tagSet = new Set()
  const industriesSet = new Set()

  allBlogData.forEach((blog) => {
    if (blog?.tags) {
      blog.tags.forEach((tag) => {
        if (tag.includes("Industry:")) {
          const cleanTag = tag.replace("Industry:", "").trim()
          industriesSet.add(cleanTag)
        } else {
          tagSet.add(tag)
        }
      })
    }
  })

  const dropdownOptions = {
    tags: [...tagSet].sort((a, b) => a.localeCompare(b)),
    industries: [...industriesSet].sort((a, b) => a.localeCompare(b))
  }

  const recommended = pageData.showRecommended
    ? await fetchRecommended(pageData?.recommendedCollection?.items, slug, "resources", preview)
    : null

  return {
    props: {
      preview,
      pageData,
      allBlogData,
      dropdownOptions,
      recommended
    }
  }
}

const BlogIndex = ({ preview, pageData, dropdownOptions, allBlogData, recommended }) => {
  const livePageData = useContentfulLiveUpdates(pageData)

  return (
    <FilterGridTemplate
      pageData={livePageData}
      contentData={allBlogData}
      dropdownOptions={dropdownOptions}
      recommendedProps={{
        recommended: recommended,
        title: "Explore Resources",
        buttonLabel: "View all resources",
        buttonUrl: "/resources"
      }}
      preview={preview}
      route={pageData.slug}
      hasTextSearch
    />
  )
}

export default BlogIndex
